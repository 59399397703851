import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Slider from 'react-slick';


import { HomepageProps } from '../../types/pages/homepage/HomepageTypes';

import getHomepageFeaturedCollections from '../../hooks/getHomepageFeaturedCollections';
import isMobile from '../../hooks/isMobile';
import { homepageBackgroundImageSource, homepageBackgroundVideoSource } from '../../constants/ui';

import CollectionSliderContainer from '../../components/homepage/CollectionSlider';
import HomepageTestimonials from './HomepageTestimonials';
import { ProductSearch } from '../products/ProductSearch';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { TopNavigationAssets } from '../../staticData/Navigation';
import CollectionGrid from './CollectionGrid';
import Cta from './Cta'

const Homepage: React.FC<HomepageProps> = (props) => {
  const { content } = props;
  const [selectedItem, setSelectedItem] = useState(TopNavigationAssets[0]);
  const [selectedCategory, setSelectedCategory] = useState(TopNavigationAssets[0].api);
  const [selectedLibraryId, setSelectedLibraryId] = useState(TopNavigationAssets[0].libraryId);
  const [mobile, setMobile] = useState<boolean>();
  const initialState = {};

  const createMarkup = (content) => {
    return { __html: content };
  };

  TopNavigationAssets.forEach((_, index) => {
    initialState[index] = index === 0;
  });

  const [hoverStates, setHoverStates] = useState(initialState);
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);

  const { data: featuredCollections } = getHomepageFeaturedCollections();

  useEffect(() => {
    const _mobile = isMobile();
    setMobile(_mobile);
  }, []);

  const handleMouseOver = (index) => {
    setHoverStates(index);
  };

  const handleMouseOut = () => {
    setHoverStates(null);
  };

  const handleClick = (item, index) => {
    setSelectedItem(item);
    setSelectedLibraryId(item.libraryId);
    setSelectedCategory(() => item.api);
    setHoverStates({ ...initialState, [index]: true });
  };

  const selectedClass = 'text-blue-800';

  const renderBackgroundVideo = (): React.ReactElement => {
    return (
      <>
        <video
          className="absolute z-0 w-auto min-w-full h-[500px] md:h-[648px] xl:h-[724px] object-cover object-center"
          preload="auto"
          autoPlay
          loop={true}
          muted
          poster={homepageBackgroundImageSource}>
          <source src={homepageBackgroundVideoSource} />
        </video>
        <div className="absolute z-1 w-[320px] sm:w-1/2 h-[500px] md:h-[648px] xl:h-[724px] left-0 top-0 header-gradient"></div>
      </>
    );
  };

  const renderBackgroundImage = (): React.ReactElement => {
    return (
      <div className="absolute z-0 top-0 w-full overflow-hidden h-[265px]">
        <div className="w-full pb-[36%] scale-[1.75]">
          <Image src={homepageBackgroundImageSource} priority={true} fill={true} alt="bg-image" />
          <div className="absolute z-1 w-[200px] sm:w-1/2 h-[265px] md:h-[648px] xl:h-[724px] left-0 top-0 header-gradient"></div>
        </div>
      </div>
    );
  };

  const renderBackground = (): React.ReactElement => {
    switch (mobile) {
      case true:
        return renderBackgroundImage();
      case false:
        return renderBackgroundVideo();
      case undefined:
      default:
        return <></>;
    }
  };

  return (
    <div className="homepage text-white">
      <section className="w-full h-[500px] min-[640px]:h-[660px] md:h-[648px] xl:h-[724px] flex items-center relative z-30">
        {renderBackground()}

        <div className="mt-64 sm:mt-0 px-6 sm:px-8 md:px-24 relative">
          <div dangerouslySetInnerHTML={createMarkup(content.main_heading)} />
          <div className="flex sm:hidden">
            {TopNavigationAssets.map((item, index) => (
              <div key={index} className="relative mt-6">
                <li
                  className={`cursor-pointer flex items-center ${selectedItem === item && hoverStates
                    ? selectedClass
                    : 'text-white hover:text-a-blue'
                    }`}
                  onClick={() => handleClick(item, index)}>
                  <div className="flex mb-2 gap-5">
                    <div
                      className={`flex flex-col ${selectedItem === item
                        ? 'border-a-blue border-b-[2px]'
                        : 'border-transparent'
                        }`}>
                      <div className="text-[14px] mb-1">{item.text}</div>
                    </div>
                    <div className="flex flex-col items-center"></div>
                  </div>
                </li>
              </div>
            ))}
          </div>
          <div className="w-full mt-2 sm:mt-10 gap-4 mb-10">
            <ProductSearch
              placeholder="Search"
              selectedCategory={selectedCategory}
              setSelectedLibraryId={setSelectedLibraryId}
              selectedLibraryId={selectedLibraryId}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              className="pl-2 w-full h-full bg-transparent text-[18px] font-light leading-[20px] tracking-wide placeholder-gray-600 outline-none"
              containerClass="w-full sm:w-[85%] lg:w-[600px] xl:w-[800px] border border-white rounded-[5px] flex items-center px-4 h-[55px] bg-white text-black md:min-w-[400px]"
              searchResultsDropdown="w-full sm:w-[85%] lg:w-[600px] xl:w-[800px]"
              resultsOverlayClass="hidden"
              searchIconClass={true}
              chevronClass="stroke-black"
              dropdownAdjustments="mt-[9px] w-[218px] 2xl:min-w-[232px] text-[14px] 2xl:text-[16px] leading-[24px]"
              homePage={true}
              searchActive={true}
            />
          </div>
        </div>
      </section>

      <section className="flex flex-col lg:items-center bg-a-dark-gray">
        <div className="flex ml-5 lg:ml-0 my-8 !font-semibold lg:my-28 text-[24px] lg:text-[34px] 2xl:text-[36px]">
          <div dangerouslySetInnerHTML={createMarkup(content.product_showcase_heading)} />
        </div>
        <div className="hidden lg:flex lg:gap-8 2xl:gap-28 relative">
          {TopNavigationAssets.map((item, index) => (
            <div key={index} className="flex flex-col">
              <div className="pl-1 relative">
                <li
                  className={`cursor-pointer flex items-center mb-1 ${selectedItem === item ? selectedClass : 'text-[#AEB6BA] hover:text-a-blue'
                    }`}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseLeave={() => handleMouseOut()}
                  onClick={() => handleClick(item, index)}>
                  <div className="flex gap-6 mb-6 items-center justify-center left-0">
                    {selectedItem === item || hoverStates === index
                      ? item.iconFilledLarge
                      : item.iconOutlinedLarge}
                    <div className="flex flex-col">
                      <div className="text-[18px] 2xl:text-[24px] leading-[24px] font-[500] 2xl:mb-[6px]">
                        {item.text}
                      </div>
                      <p className="text-[12px] 2xl:text-[16px] leading-[24px] font-[400] !text-[#AEB6BA]">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </li>
              </div>
              {/* Dynamic horizontal divider */}
              <div className="w-full flex">
                <hr
                  className={`z-10 border-t ${selectedItem === item ? 'border-a-blue border-[3px]' : 'border-transparent'
                    } w-full`}></hr>
              </div>
              <div
                className={`absolute bottom-0 ${index === 1 ? 'w-1/2 2xl:w-3/4 right-40' : 'w-1/3'
                  } ${index === 2 ? 'right-0' : ''}`}>
                <hr className="border-t border-gray-800 border-[.5px]"></hr>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden lg:flex justify-center w-11/12 mb-16 mt-12">
          {featuredCollections && (
            <CollectionGrid collections={featuredCollections[selectedItem.library]} />
          )}
        </div>
        <MainLinkButton
          href={selectedItem.href}
          className="hidden !bg-[#00A1E0] lg:flex gap-2 !w-[258px] h-[56px] mb-20 text-white hover:!bg-[#24C1FF]">
          ALL {selectedItem.text}
          <div className="mt-[.5]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16">
              <path
                stroke="#F5F5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M2.5 8h11M9 3.5L13.5 8 9 12.5"></path>
            </svg>
          </div>
        </MainLinkButton>

        <div>
          {TopNavigationAssets.map((item, index) => (
            <React.Fragment key={index}>
              <div key={index} className="lg:hidden ml-6">
                <li
                  className={`cursor-pointer flex items-center ${selectedClass}`}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseLeave={() => handleMouseOut()}
                  onClick={() => handleClick(item, index)}>
                  <div className="flex items-center gap-4 mb-4">
                    <div
                      className="flex"
                      style={{
                        fillOpacity: '1',
                        scale: '1'
                      }}>
                      {item.iconFilled}
                    </div>
                    <div className="flex flex-col">
                      <div className="text-[20px] leading-[24px] gap-3">{item.text}</div>
                      <p className="text-[14px] leading-[24px] font-[400] !text-a-light-gray">
                        {item.description}
                      </p>
                    </div>
                    <div className="flex flex-col items-center"></div>
                  </div>
                </li>
              </div>
              <div className="flex flex-col mb-[-20px] md:mb-4 md:w-full lg:hidden ml-5">
                {featuredCollections && item.library && featuredCollections[item.library] && (
                  <CollectionSliderContainer
                    key={item.library}
                    collections={featuredCollections[item.library]}
                  />
                )}
              </div>
              <div className="flex px-6">
                <button className="lg:hidden font-[Eurostile] w-full h-[56px] md:w-72 uppercase mb-10 md:ml-0 px-6 py-4 bg-[#FFFFFF] bg-opacity-[10%] text-[#7F898D] !text-[17px] leading-[24px] tracking-wider font-[500] rounded-[5px]">
                  <Link href={item.href}>all {item.text}</Link>
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>
      </section>

      <section className="py-12 md:pt-12 md:mb-12 xl:mb-12 bg-a-darker-gray text-center">
        <div dangerouslySetInnerHTML={createMarkup(content?.poster_section_heading)} />
        <Slider
          {...{
            className: 'center',
            centerMode: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            centerPadding: '60px',
            slidesToShow: 6,
            autoplaySpeed: 2200,
            speed: 700,
            swipeToSlide: true,
            lazyLoad: 'progressive',
            responsive: [
              {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          }}>
          {content?.homepage_posters?.map((item, key) => (
            <div key={key} className="pr-3 outline-0">
              <img
                className="rounded-[5px] max-h-[592.5px]"
                src={item.filename}
                alt={item.alt}
                title={item.title}
                loading="lazy"
                style={{ aspectRatio: '2/3', overflow: 'clip', objectFit: 'fill' }}
              />
            </div>
          ))}
        </Slider>
      </section>

      <Cta
        backgroundImage={content.so_cta_bg_img.filename}
        heading={content.cta_text_heading}
        subheading={content.cta_text_subheading}
        buttonText={content.cta_button_text}
        variant={content.cta_button_color}
        buttonHref="/pricing"
      />

      <section className="w-full flex p-4 pt-12 md:p-24 gap-12 flex-col md:flex-row items-center max-w-[1920px] mx-auto">
        <div className="flex-col justify-center align-middle items-center">
          <div dangerouslySetInnerHTML={createMarkup(content.blurb_heading)} />
          <div dangerouslySetInnerHTML={createMarkup(content.blurb_description)} />

          <div className="py-6">
            <img
              src={content.blurb_logos.filename}
              className="max-w-[250px]"
              alt="Software that work with actionVFX"
              loading="lazy"
            />
          </div>
          <div className={`${content.blurb_button_link.url === '/blog?category=tutorials' && 'hidden'} pt-8`}>
            <MainLinkButton
              href={content.blurb_button_link.url}
              className="!bg-[#ffffff] !bg-opacity-[5%] w-full text-[#7F898D] md:!w-[215px] !h-[56px] md:text-[#24C1FF] md:!bg-[#1F3D48B2] md:hover:!bg-[#114C63B2]">
              {content.blurb_button_text}
            </MainLinkButton>
          </div>
        </div>
        <div className="rounded-[7px] overflow-hidden ml-auto">
          <img src="/img/home/artist.jpg" alt="Artist using ActionVFX Elements" loading="lazy" />
        </div>
      </section>

      <div className="bg-gray-900">
        <section className="w-full p-8 md:p-24 flex flex-col md:flex-row gap-16 mt-12 max-w-[1920px] mx-auto">
          <div className="w-full md:w-2/3 lg:w-1/2 bg-a-blue rounded-[5px]">
            <HomepageTestimonials {...content.testimonials[selectedTestimonial]} />
            <ul role="tablist" className="flex gap-2 mt-[-30px] items-center justify-center pb-6">
              {content?.testimonials.map((t, k) => (
                <li role="presentation" key={`testimonial_${k}`}>
                  <button
                    className={`rounded-full bg-white ${selectedTestimonial == k ? '' : 'opacity-70'
                      } w-[10px] h-[10px]`}
                    onClick={(e) => setSelectedTestimonial(k)}
                    type="button"
                    role="tab"
                    value={k}></button>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/3 lg:w-1/2 pb-8">
            <div dangerouslySetInnerHTML={createMarkup(content.testimonial_section_heading)} />
            <div dangerouslySetInnerHTML={createMarkup(content.testimonial_section_description)} />
            <div className="pt-4">
              <MainLinkButton
                href={content.testimonial_section_button_link.url}
                className="!w-full md:!w-[220px] lg:!w-[281px] !h-[56px] !bg-[#FFFFFF1A] !text-[#7F898D] md:!text-[#24C1FF] md:!bg-[#1F3D48B2] md:hover:!bg-[#114C63B2]"
                variant="outlined">
                {content.testimonial_section_button_text}
              </MainLinkButton>
            </div>

            <img
              src={content.testimonial_section_img.filename}
              alt="actionvfx partners"
              className="w-[500px] mt-20"
              loading="lazy"
            />
          </div>
        </section>
      </div>

      <Cta
        backgroundImage={content.so_cta_bg_img_2?.filename}
        heading={content.cta_text_heading_2}
        subheading={content.cta_text_subheading_2}
        buttonText={content.cta_button_text_2}
        variant={content.cta_button_color_2}
        buttonHref="/pricing"
      />
    </div>
  );
};

export default Homepage;
